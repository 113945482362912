<template>
    <modal ref="modalUpdateEdad" titulo="Actualiza tu edad" tamano="modal-md" icon="confirmar-pedido" :adicional="textBoton" no-aceptar @adicional="accionBoton">
        <div v-show="!isMayor" class="row">
            <div class="col-12 justify-content-center d-middle text-general">
                ¿Eres mayor de edad?
            </div>
        </div>
        <div v-show="isMayor" class="row f-14 my-2 mx-0 justify-content-center d-middle text-general">
            <div class="col-7">
                <div class="row">
                    <div class="col">
                        <p class="ml-2 text-general f-13">Fecha de Nacimiento</p>
                        <el-date-picker
                        v-model="fecha"
                        type="date"
                        size="small"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Datos from '~/services/tiendas/cambio_datos'
import moment from 'moment'
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > moment().subtract(1,'days');
                }
            },
            fecha:'',
            isMayor:false

        }
    },
    computed:{
        textBoton(){
            if(this.isMayor){
                return "Guardar"
            }else{
                return "Si lo soy"
            }
        }
    },
    methods: {
        toggle(){
            this.isMayor = false
            this.fecha = this.$usuario.nacimiento
            this.$refs.modalUpdateEdad.toggle()
        },
        accionBoton(){
            if(this.isMayor){
                this.updateEdad()
            }else{
                this.isMayor = true
            }
        },
        async updateEdad(){
            try {

                const form = {
                    fecha:this.fecha,
                    id_user: this.$usuario.id,
                    tipo:2 // 2 Tendero
                }
                const {data} = await Datos.updateEdad(form)
                this.notificacion('','Tú edad ha sido actualizada en el sistema','success')
                this.$store.dispatch('auth/consultarUsuario')
                this.$refs.modalUpdateEdad.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
